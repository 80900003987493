import React from "react";
import { useNavigate } from "react-router-dom";
import logoWhite from "../assets/sunflogowhite.svg";
import "./header-component.css";

const Header = () => {
  const navigate = useNavigate();

  const navigateToPage = (navigateTo) => {
    navigate(navigateTo);
  };

  return (
    <header className="app-header">
      <div className="header-menu">
        <p className="menu-link" onClick={() => navigateToPage("/sobre")}>
          contato
        </p>
        <p> ⸺ </p>
        <p className="menu-link" onClick={() => navigateToPage("/projetos")}>
          projetos
        </p>
      </div>
      <div className="home-btn" onClick={() => navigateToPage("/")}>
        <img src={logoWhite} className="sunflower-logo" alt="logo" />
      </div>
      <div className="header-menu">
        <p>contato</p>
        <p>⸺</p>
        <p>projetos</p>
      </div>
    </header>
  );
};

export default Header;
