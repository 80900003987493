import React from "react";
import "./Project.css";

const Project = ({ project }) => {
  return (
    <div className="project-container">
      <div className="project-details">
        <div className="project-info">
          <div className="project-name">
            {project.title + "⸺" + project.category}
          </div>
          <div className="project-description">{project.description}</div>
        </div>
        <div className="team-info">
          <div className="team-resp">
            <p>entrega</p>
            <br />
            {project.roles.map((z, key) => (
              <div key={key + "role"}>{z}</div>
            ))}
          </div>
          <div className="team-members">
            <p>equipe</p>
            <br />
            {project.team.map((z, key) => (
              <div key={key + "member"}>{z}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="video-responsive">
        <iframe
          src={project.videoUrl}
          frameBorder="0"
          allowFullScreen
          title={project.title}
          className="responsive"
        ></iframe>
      </div>
    </div>
  );
};

export default Project;
