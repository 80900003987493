import "./Main.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

const Main = ({ projects }) => {
  const [projectList, setProjectList] = useState(projects);

  useEffect(() => {
    setProjectList(projects);
  }, [projects]);

  const navigate = useNavigate();

  const navigateToPage = (navigateTo) => {
    navigate(navigateTo);
  };

  return projectList.map((z) => (
    <div key={z.title + "project" + z.index} className="project">
      <div
        key={z.title + "overlay" + z.index}
        className="overlay"
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "instant" });
          navigateToPage(`${"/" + z.title + z.index}`);
        }}
      ></div>
      <video
        key={z.title + "video" + z.index}
        // id={z.title.replace(/\s/g, "") + "-" + z.index}
        className="video-full"
        autoPlay
        loop
        muted
        disablePictureInPicture
        playsInline
        poster={z.videoThumbnail}
      >
        <source
          key={z.title + "src" + z.index}
          src={z.videoPreviewUrl}
          type="video/mp4"
        />
      </video>
      {/* <video
          className="video-full-overlay"
          autoPlay
          loop
          muted
          disablePictureInPicture
          playsInline
          poster={z.videoThumbnail}
          >
          <source src={z.videoPreviewUrl} type="video/mp4" />
          </video>
          <video
          className="video-full-g-overlay"
          autoPlay
          loop
          muted
          disablePictureInPicture
          playsInline
          poster="https://sunflower-public.s3.sa-east-1.amazonaws.com/grain_poster.png"
          >
          <source
          src="https://sunflower-public.s3.sa-east-1.amazonaws.com/grain.mp4"
          type="video/mp4"
          />
        </video> */}
      <div key={z.title + "title" + z.index} className="project-title">
        {z.category} ⸺ {z.title}
      </div>
    </div>
  ));
};

export default Main;
