import React from "react";
import logoBlack from "../assets/sunflogoblack.svg";
import "./footer-component.css";

const footer = () => {
  return (
    <footer className="app-footer">
      <div className="footer-wide">
        <div className="footer-text">
          <p>© sunflower media co. 2024</p>
        </div>
        <img src={logoBlack} className="sunflower-logo-sm" alt="logo" />
        <div className="footer-text">
          <a href="mailto:sunflowermediacc@gmail.com" className="mail-link">
            sunflowermediacc@gmail.com
          </a>
        </div>
      </div>
      <div className="footer-mobile">
        <img src={logoBlack} className="sunflower-logo-sm" alt="logo" />
        <div className="footer-text">
          <a href="mailto:sunflowermediacc@gmail.com" className="mail-link">
            sunflowermediacc@gmail.com
          </a>
        </div>
        <div className="footer-text">
          <p>© sunflower media co. 2024</p>
        </div>
      </div>
    </footer>
  );
};

export default footer;
