import "./About.css";
import sunflowerText from "../../assets/sunfTextWhite.png";

const About = () => {
  var teamMembers = [
    {
      member: "Giovanna Ohl",
      role: "diretora, cinegrafista, fotógrafa",
    },
    { member: "Leandro Bartolozzi", role: "fotógrafo, editor, designer" },
    { member: "Matheus Fedel", role: "cinegrafista, editor, animador" },
  ];

  return (
    <>
      <div className="about" id="about">
        <div className="container">
          <div className="c-first-element">
            <p className="primary-text">
              a sunflower é uma produtora criativa sediada em são paulo.
              desenvolvemos produções artísticas e institucionais para
              diferentes segmentos do mercado.
            </p>
            <p className="primary-text">
              criação ⸺ roteirização ⸺ produção ⸺ captação ⸺ edição ⸺ animação
              gráfica ⸺ finalização ⸺ design gráfico
            </p>
          </div>
          <div className="c-second-element">
            <p className="secondary-text" id="span-a">
              tem uma ideia incrível que pode se tornar uma produção
              audiovisual? entre em contato com a sunflower ⸺ a gente não morde
              ⸺
            </p>
          </div>
        </div>
        <div className="banner">
          <img
            src="https://sunflower-public.s3.sa-east-1.amazonaws.com/banner-sunflower.gif"
            alt="sunflower-banner"
            className="banner-image"
          />
        </div>
        <div className="container">
          <div className="c-first-element">
            <div className="primary-text">time</div>
          </div>
          <div className="c-second-element">
            {teamMembers.map((z, key) => (
              <div key={key} className="team-member">
                <div className="member-name">{z.member}</div>
                <div className="member-role">{z.role}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="container">
          <div className="c-first-element">
            <img
              src={sunflowerText}
              alt="sunflower"
              className="sunflower-text"
            />
          </div>
          <div className="c-second-element">
            <div className="secondary-text">
              <a href="mailto:sunflowermediacc@gmail.com" className="link">
                sunflowermediacc@gmail.com
              </a>
            </div>
            <div className="secondary-text">
              <a
                href="https://www.instagram.com/sunflower.mco/"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                @sunflower.mco
              </a>
            </div>
            <div className="secondary-text">
              <a
                href="//api.whatsapp.com/send?phone=5511966450317&text=olá"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                55-11-96645-0317
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
